import {getCookie, setCookie} from "../functions";

document.querySelectorAll('.location-preview').forEach((item, index) => item.addEventListener('mouseenter', function () {
    document.querySelectorAll('.location-preview').forEach(subitem => {
        subitem.classList.remove('active');
        subitem.classList.add('shadow-enabled');
    });
    item.classList.add('active');
    item.classList.remove('shadow-enabled');
}));

document.querySelectorAll('.location-preview').forEach(item => item.addEventListener('mouseleave', function () {
    document.querySelectorAll('.location-preview').forEach(subitem => {
        subitem.classList.remove('active');
        subitem.classList.remove('shadow-enabled');
    });
    document.querySelectorAll('.location-preview')[0].classList.add('active');
}));
document.querySelectorAll('[data-location]').forEach(item => item.addEventListener('click', function () {
    let domain = item.getAttribute('domain');
    let urlPath = window.location.pathname;
    let currentDomain = window.location.origin;
    let locationIndex = item.getAttribute('index');
    
    if (domain != currentDomain) {
        window.location.replace(domain + urlPath + '?location=' + locationIndex);
        return;
    }

    if (item.classList.contains('active'))
        return;

    setCookie('location', encodeURIComponent(item.getAttribute('data-location')), '7');
    window.location.reload();
}));

window.addEventListener('DOMContentLoaded', function () {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('location')) {
        const locationIndex = urlParams.get('location');
        
        let items = document.querySelectorAll('[data-location]');
        let needleItem = items[locationIndex];

        for(let i = 0; i < items.length; i++) {
            items[i].classList.remove('active');
        }

        needleItem.classList.add('active');

        let location = needleItem.getAttribute('data-location');

        setCookie('location', encodeURIComponent(location), '7');

        window.history.replaceState({}, '', window.location.pathname);
        // window.location.reload();
    } else {
        if (!getCookie('location')) {
            setCookie('location', document.querySelector('.cities-block-item.active').getAttribute('data-location'), '7');
        }
    }
})