import axios from "axios";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {getCookie, setCookie} from "../functions";

if (!getCookie('hideLocationPopup')) {
    axios.get(api_settings.ajax_url, {
        params: {
            nonce: api_settings.nonce,
            action: 'get_location_info',
        }
    })
        .then(function (response) {
            const data = response.data;

            if (data.show_popup) {
                axios.get(api_settings.ajax_url, {
                    params: {
                        nonce: api_settings.nonce,
                        action: 'get_popup_location_info',
                    }
                })
                    .then(function (response) {
                        const data_popup = response.data;
                        const lang_array = data_popup.lang_array;
                        const common_locations = data_popup.common_locations;

                        const uniqueLocationsByLocale = Array.from(
                            new Map(common_locations.map(item => [item.locale, item])).values()
                        );

                        const title_button = document.querySelector('[name="button_lang_popup_text"]').value;
                        const title_locations = document.querySelector('[name="lang_popup_locations_title"]').value;
                        const title_lang = document.querySelector('[name="lang_popup_lang_title"]').value;
                        const lang_popup_title = document.querySelector('[name="lang_popup_title"]').value;
                        const template_directory_uri = document.querySelector('[name="template_directory_uri"]').value;

                        let popupHTML = `
                            <div id="popup-lang" class="popup-lang">
                                <div class="popup-lang__container">
                                    <div class="popup-lang__header">
                                        <p class="popup-lang__title">${lang_popup_title}</p>
                                        
                                        <div class="popup-lang__close">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                              <path d="M19.2109 6.70331C19.6017 6.31251 19.6017 5.6789 19.2109 5.2881C18.8201 4.89729 18.1865 4.89729 17.7957 5.2881L12.499 10.5847L7.20238 5.2881C6.81158 4.89729 6.17797 4.89729 5.78717 5.2881C5.39636 5.6789 5.39636 6.31251 5.78717 6.70331L11.0838 12L5.78717 17.2966C5.39636 17.6874 5.39636 18.321 5.78717 18.7118C6.17797 19.1026 6.81158 19.1026 7.20238 18.7118L12.499 13.4152L17.7957 18.7118C18.1865 19.1026 18.8201 19.1026 19.2109 18.7118C19.6017 18.321 19.6017 17.6874 19.2109 17.2966L13.9142 12L19.2109 6.70331Z" fill="#F8F8F8"/>
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    <div class="popup-lang__body">
                        `;

                        if (uniqueLocationsByLocale.length > 0) {
                            popupHTML += `
                                <div class="popup-lang__block popup-lang-subblock popup-lang__block--locale">
                                    <div class="popup-lang__subtitle popup-lang-subblock__title">${title_locations}</div>
                                    
                                    <div class="popup-lang-subblock__row">
                            `;

                            for (let i = 0; i < uniqueLocationsByLocale.length; i++) {

                                const item_class = i === 0 ? 'popup-lang-subblock-item active' : 'popup-lang-subblock-item';

                                popupHTML += `
                                    <div class="${item_class}" data-value="${uniqueLocationsByLocale[i]['domain']}">
                                        <div class="popup-lang-subblock-item__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M12 6C11.2583 6 10.5333 6.21993 9.91661 6.63199C9.29993 7.04404 8.81928 7.62971 8.53545 8.31494C8.25162 9.00016 8.17736 9.75416 8.32205 10.4816C8.46675 11.209 8.8239 11.8772 9.34835 12.4017C9.8728 12.9261 10.541 13.2833 11.2684 13.4279C11.9958 13.5726 12.7498 13.4984 13.4351 13.2145C14.1203 12.9307 14.706 12.4501 15.118 11.8334C15.5301 11.2167 15.75 10.4917 15.75 9.75C15.75 8.75544 15.3549 7.80161 14.6517 7.09835C13.9484 6.39509 12.9946 6 12 6ZM12 12C11.555 12 11.12 11.868 10.75 11.6208C10.38 11.3736 10.0916 11.0222 9.92127 10.611C9.75097 10.1999 9.70642 9.7475 9.79323 9.31105C9.88005 8.87459 10.0943 8.47368 10.409 8.15901C10.7237 7.84434 11.1246 7.63005 11.561 7.54323C11.9975 7.45642 12.4499 7.50097 12.861 7.67127C13.2722 7.84157 13.6236 8.12996 13.8708 8.49997C14.118 8.86998 14.25 9.30499 14.25 9.75C14.25 10.3467 14.0129 10.919 13.591 11.341C13.169 11.7629 12.5967 12 12 12ZM12 1.5C9.81273 1.50248 7.71575 2.37247 6.16911 3.91911C4.62247 5.46575 3.75248 7.56273 3.75 9.75C3.75 12.6938 5.11031 15.8138 7.6875 18.7734C8.84552 20.1108 10.1489 21.3151 11.5734 22.3641C11.6995 22.4524 11.8498 22.4998 12.0037 22.4998C12.1577 22.4998 12.308 22.4524 12.4341 22.3641C13.856 21.3147 15.1568 20.1104 16.3125 18.7734C18.8859 15.8138 20.25 12.6938 20.25 9.75C20.2475 7.56273 19.3775 5.46575 17.8309 3.91911C16.2843 2.37247 14.1873 1.50248 12 1.5ZM12 20.8125C10.4503 19.5938 5.25 15.1172 5.25 9.75C5.25 7.95979 5.96116 6.2429 7.22703 4.97703C8.4929 3.71116 10.2098 3 12 3C13.7902 3 15.5071 3.71116 16.773 4.97703C18.0388 6.2429 18.75 7.95979 18.75 9.75C18.75 15.1153 13.5497 19.5938 12 20.8125Z" fill="#CACBCF"/>
                                            </svg>
                                        </div>

                                        <span>${uniqueLocationsByLocale[i]['country_name']}</span>
                                    </div>
                                `
                            }

                            popupHTML += '</div></div>';
                        }

                        if (lang_array) {
                            popupHTML += `
                                <div class="popup-lang__block popup-lang-subblock popup-lang__block--language">
                                    <div class="popup-lang__subtitle popup-lang-subblock__title">${title_lang}</div>
                                    
                                    <div class="popup-lang-subblock__row">
                            `;

                            for (const key in lang_array) {
                                const item_class = key === Object.keys(lang_array)[0] ? 'popup-lang-subblock-item active' : 'popup-lang-subblock-item';
                                const item_img_src = `${template_directory_uri}/img/flags/flag_${key}.svg`;

                                popupHTML += `
                                    <div class="${item_class}" data-value="${key}">
                                        <div class="popup-lang-subblock-item__icon popup-lang-subblock-item__icon--rounded">
                                            <img src="${item_img_src}" alt="${lang_array[key].name}">
                                        </div>
                                        
                                        <span>${lang_array[key].name}</span>
                                    </div>
                                `;
                            }

                            popupHTML += '</div></div>';
                        }

                        popupHTML += `
                                </div>
                                
                                <button class="popup-lang__button block-lines transition-effect mt-auto outline-none flex items-center justify-between gap-[12px] p-[16px] w-full rounded-[53px] bg-[#F8F8F8] lg:max-w-[calc(100%_-_8px)]">
                                    <span class="text-[16px] font-semibold leading-[1.5] lg:text-[21px]">${title_button}</span>
            
                                    <div class="block-lines__child lg:min-w-[32px] lg:max-w-[32px] lg:w-[32px]">
                                        <svg class="lg:!min-w-[32px] lg:!w-[32px] lg:!h-[32px]" xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0645 5.29289C14.674 4.90237 14.0408 4.90237 13.6503 5.29289C13.2598 5.68342 13.2598 6.31658 13.6503 6.70711L17.9432 11L4.35742 11C3.80514 11 3.35742 11.4477 3.35742 12C3.35742 12.5523 3.80514 13 4.35742 13L17.9432 13L13.6503 17.2929C13.2598 17.6834 13.2598 18.3166 13.6503 18.7071C14.0408 19.0976 14.674 19.0976 15.0645 18.7071L21.0645 12.7071C21.4551 12.3166 21.4551 11.6834 21.0645 11.2929L15.0645 5.29289Z" fill="#131625"></path>
                                        </svg>
            
                                        <svg class="lg:!min-w-[32px] lg:!w-[32px] lg:!h-[32px]" xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0645 5.29289C14.674 4.90237 14.0408 4.90237 13.6503 5.29289C13.2598 5.68342 13.2598 6.31658 13.6503 6.70711L17.9432 11L4.35742 11C3.80514 11 3.35742 11.4477 3.35742 12C3.35742 12.5523 3.80514 13 4.35742 13L17.9432 13L13.6503 17.2929C13.2598 17.6834 13.2598 18.3166 13.6503 18.7071C14.0408 19.0976 14.674 19.0976 15.0645 18.7071L21.0645 12.7071C21.4551 12.3166 21.4551 11.6834 21.0645 11.2929L15.0645 5.29289Z" fill="#131625"></path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                        `;

                        new Fancybox(
                            [
                                {
                                    src: popupHTML,
                                    type: "html",
                                },
                            ],
                            {
                                on: {
                                    done: function () {
                                        document.querySelector('body').classList.add('popup-lang-opened');
                                    },
                                    destroy: function () {
                                        document.querySelector('body').classList.remove('popup-lang-opened');
                                        setCookie('hideLocationPopup', '1', '7');
                                    }
                                }
                            }
                        );
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

document.body.addEventListener('click', function(event) {
    if (event.target.closest('.popup-lang__button') || event.target.classList.contains('popup-lang__close')) {
        const clicked_el = event.target.classList.contains('popup-lang__close') ? event.target : event.target.closest('.popup-lang__button');

        clicked_el.setAttribute('disabled', 'true');

        const popup_el = event.target.closest('.popup-lang');

        const lang = popup_el.querySelector('.popup-lang__block--language .popup-lang-subblock-item.active').dataset.value;
        const domain = popup_el.querySelector('.popup-lang__block--locale .popup-lang-subblock-item.active').dataset.value;
        const is_category = document.querySelector('[name="is_category"]').value;
        const current_slug = document.querySelector('[name="current_slug"]').value;
        const post_type = document.querySelector('[name="post_type"]').value;
        const taxonomy = document.querySelector('[name="taxonomy"]').value;

        axios.get(api_settings.ajax_url, {
            params: {
                nonce: api_settings.nonce,
                lang,
                domain,
                is_category,
                current_slug,
                post_type,
                taxonomy,
                action: 'get_popup_lang_redirect',
            }
        })
            .then(function (response) {
                setCookie('hideLocationPopup', '1', '7');

                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                }
                else {
                    Fancybox.close();
                }
            })
            .catch(function (error) {
                    console.log(error);
                });
    }
});